<template>
    <div>
        <template v-if="direction === 'reverse'">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.906"
                height="7.959"
                viewBox="0 0 12.906 7.959"
                class="thumbnail-arrow"
                @click="toggleSceneThumbnails"
            >
                <path
                    data-name="Color"
                    d="M1.514 0 0 1.516l4.919 4.937L0 11.389l1.514 1.516 6.445-6.452z"
                    transform="rotate(90 6.453 6.453)"
                    style="fill:#333"
                />
            </svg>
        </template>
        <template v-if="direction === 'right'">
            <svg
                width="25"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z"
                    fill="#000"
                />
            </svg>
        </template>
        <template v-if="direction === 'left'">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="22"
                height="22"
                viewBox="0 0 25 25"
            >
                <g
                    id="Group_2168"
                    data-name="Group 2168"
                    transform="matrix(-0.999, 0.035, -0.035, -0.999,30,30)"
                >
                    <path
                        id="_Color"
                        data-name=" ↳Color"
                        d="M1.607,13.693,0,12.084,5.219,6.847,0,1.609,1.607,0,8.444,6.847Z"
                        transform="translate(14.236 10.202)"
                    />
                </g>
            </svg>
        </template>
    </div>
</template>

<script>
export default {
    name: 'CaretSVG',
    props: {
        direction: {
            type: String,
            default: '',
        },
    },
}
</script>
