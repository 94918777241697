<template>
    <div
        v-show="isActive"
        :class="{
            vertical_content: verticalTab,
            tab__content: !verticalTab,
            filter_content: filterTab,
        }"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Tab',
        },
        img: {
            type: String,
        },
        blur: {
            type: Boolean,
        },
        verticalTab: {
            type: Boolean,
        },
        filterTab: {
            type: Boolean,
        },
    },
    data() {
        return {
            isActive: true,
        }
    },
    mounted() {
        this.isActive = this.selected
    },
}
</script>
<style lang="scss">
@import './Tab.scss';
</style>
