<template>
    <div
        class="accordion"
        :id="id"
        :class="id == selected ? 'accordian-shadow' : ''"
    >
        <div
            class="accordion-header"
            @click="openAccordion"
            :class="id == selected ? 'accordian-active' : ''"
        >
            <div class="header-left">
                <slot name="header-left"></slot>
            </div>
            <div class="header-right" v-if="!rightCenter">
                <slot name="header-right"></slot>
                <font-awesome-icon icon="chevron-down" class="accordion-icon" />
            </div>
            <div class="header-right-center" v-else>
                <slot name="header-right-center"></slot>
                <svg
                    width="25"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="accordion-icon"
                    :class="id == selected ? 'icon-transition' : ''"
                >
                    <path
                        d="m16.865 8.6-4.59 4.574L7.685 8.6l-1.41 1.408 6 5.992 6-5.992-1.41-1.408z"
                        fill="#000"
                    />
                </svg>
            </div>
        </div>
        <transition
            name="accordion-item"
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition"
        >
            <div
                v-if="id == selected"
                class="accordion-body"
                style="overflow: hidden;"
            >
                <slot name="body"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Accordion',
    props: ['defaultOpen', 'rightCenter', 'activeHeader', 'id', 'selected'],
    components: {},
    data() {
        return {
            active: false,
        }
    },
    mounted() {
        this.active = this.defaultOpen == true ? true : false
    },
    methods: {
        openAccordion(e) {
            this.$emit('selectedAccordian', this.id)
            this.active = !this.active
            if (this.id == this.selected) {
                this.$emit('selectedAccordian', null)
            }
        },

        startTransition(el) {
            el.style.height = el.scrollHeight + 'px'
        },

        endTransition(el) {
            el.style.height = ''
        },
    },
}
</script>

<style lang="scss" scoped>
@import './Accordion.scss';
</style>
