<template>
    <div class="">
        <div class="">
            <div class="task-container">
                <div class="task">
                    <div class="bar">
                        <span
                            class="line"
                            :class="{
                                solid_color: status.completed,
                                dashed_color: status.in_progress,
                                dashed_line: status.pending,
                            }"
                        ></span>
                        <span
                            class="marker"
                            :class="{
                                tick_marker: status.completed,
                                color_dot: status.in_progress,
                                gray_dot: status.pending,
                            }"
                        ></span>
                    </div>
                    <slot></slot>
                    <div class="task-description">
                        <div class="description">
                            <Accordion>
                                <template v-slot:header-left>
                                    <div class="phase_name">
                                        {{ name }}
                                    </div>
                                </template>
                                <template v-slot:body>
                                    <div
                                        class="task"
                                        v-for="(task,index) in tasks"
                                        :key="'step_'+task.task_name+'_'+index"
                                        :class="{
                                            solid: task.status == 'Finished',
                                            hollow: task.status == 'Processing',
                                            gray: task.status == 'Pending',
                                        }"
                                    >
                                        <div class="task_details">
                                            <span class="name">
                                                {{ task.task_name }}
                                            </span>
                                            <span
                                                class="due_date"
                                                v-if="
                                                    task.status == 'Pending' &&
                                                        phase_status ==
                                                            'Processing'
                                                "
                                            >
                                                Due {{ task.due_date }}
                                            </span>
                                            <span
                                                class="task_progress"
                                                v-if="
                                                    task.status == 'Processing'
                                                "
                                            >
                                                In Progress
                                            </span>
                                            <div class="des">
                                                {{ task.description }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from 'componentsv2/Accordion'

export default {
    name: 'StepProgress',
    components: {
        Accordion,
    },
    props: {
        tasks: {
            type: Array,
        },
        name: {
            type: String,
        },
        phase_status: {
            type: String,
        },
        status: {
            completed: {
                type: Boolean,
            },
            in_progress: {
                type: Boolean,
            },
            pending: {
                type: Boolean,
            },
        },
    },
    data() {
        return {}
    },
}
</script>

<style lang="scss">
@import './StepProgress.scss';
</style>
