var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{},[_c('div',{staticClass:"task-container"},[_c('div',{staticClass:"task"},[_c('div',{staticClass:"bar"},[_c('span',{staticClass:"line",class:{
                            solid_color: _vm.status.completed,
                            dashed_color: _vm.status.in_progress,
                            dashed_line: _vm.status.pending,
                        }}),_c('span',{staticClass:"marker",class:{
                            tick_marker: _vm.status.completed,
                            color_dot: _vm.status.in_progress,
                            gray_dot: _vm.status.pending,
                        }})]),_vm._t("default"),_c('div',{staticClass:"task-description"},[_c('div',{staticClass:"description"},[_c('Accordion',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('div',{staticClass:"phase_name"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.tasks),function(task,index){return _c('div',{key:'step_'+task.task_name+'_'+index,staticClass:"task",class:{
                                        solid: task.status == 'Finished',
                                        hollow: task.status == 'Processing',
                                        gray: task.status == 'Pending',
                                    }},[_c('div',{staticClass:"task_details"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(task.task_name)+" ")]),(
                                                task.status == 'Pending' &&
                                                    _vm.phase_status ==
                                                        'Processing'
                                            )?_c('span',{staticClass:"due_date"},[_vm._v(" Due "+_vm._s(task.due_date)+" ")]):_vm._e(),(
                                                task.status == 'Processing'
                                            )?_c('span',{staticClass:"task_progress"},[_vm._v(" In Progress ")]):_vm._e(),_c('div',{staticClass:"des"},[_vm._v(" "+_vm._s(task.description)+" ")])])])})},proxy:true}])})],1)])],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }