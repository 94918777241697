<template>
    <div class="execution-all-task">
        <div class="container">
            <h1>Expected Completion</h1>
            <p>
                {{ executionAllTask.expected_completion_time }}
            </p>
            <div class="progressbar-container" style="margin-top:20px;">
                <CircleProgressBar :phases="executionAllTask.phase_details" />
            </div>
        </div>
        <div class="timeline">
            <Tabs>
                <Tab title="Status Updates">
                    <div
                        v-for="(task, index) in executionCurrentStatus.tasks"
                        :key="'status_' + task.task_name + '_' + index"
                        class="task-container"
                    >
                        <div class="task-time">
                            {{ task.due_date }}
                        </div>
                        <div class="task">
                            <div class="vertical-bar ">
                                <span class="vertical-line"></span>
                                <span
                                    class="vertical-marker"
                                    :class="{
                                        active: task.status == 'Finished',
                                    }"
                                ></span>
                            </div>
                            <div class="task-description">
                                <div class="description">
                                    {{ task.task_name }}
                                    <div
                                        class="view-all"
                                        v-if="task.status == 'Finished'"
                                    >
                                        View Inspection Report
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab title="All Tasks Status">
                    <div
                        class="all-status-container"
                        v-for="(phase, index) in executionAllTask.phase_details"
                        :key="'all_task_' + phase.phase_name + '_' + index"
                    >
                        <StepProgress
                            :status="{
                                completed: phase.phase_status == 'Finished',
                                in_progress: phase.phase_status == 'Processing',
                                pending: phase.phase_status == 'Pending',
                            }"
                            :name="phase.phase_name"
                            :phase_status="phase.phase_status"
                            :tasks="phase.tasks"
                        />
                    </div>
                </Tab>
            </Tabs>
        </div>
    </div>
</template>

<script>
import CircleProgressBar from 'componentsv2/CircleProgressBar'
import Tabs from 'componentsv2/Tabs'
import Tab from 'componentsv2/Tab'
import StepProgress from 'componentsv2/StepProgress'

export default {
    name: 'ExecutionAllTask',
    props: ['executionCurrentStatus', 'executionAllTask'],
    components: {
        CircleProgressBar,
        Tabs,
        Tab,
        StepProgress,
    },
    data() {
        return {}
    },
}
</script>
<style lang="scss">
@import './ExecutionAllTask.scss';
</style>
