<template>
    <div class="circle-progressbar">
        <div class="progressbar" 
            v-for="(phase, index) in phases" 
            :key="index + '_' + phase.phase_name"
        >
            <span class="marker"
                :class="{   
                    tick: phase.completed == true,
                    hollow: phase.phase_status == 'Processing'
                }"
            ></span>
            <div class=""
                v-for="(task, index) in phase.tasks"
                :key="index +'_'+ task"
            >
                <span
                    class="line"
                    :class="{
                        active_line: task.status == 'Finished',
                    }"
                    :style="{ width: (100 / phase.tasks.length)*(index+1) + '%' }"
                ></span>
            </div>
            <span 
                class="name" 
                :class="{
                    active: phase.phase_status == 'Finished',
                }"
            >
            {{ phase.phase_name }}
            </span>
            <span class="tasks"> {{ phase.tasks.length }} Tasks </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CircleProgressBar',
    props: {
        phases: {
            type: Array,
            tasks: {
                type: Array
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import './CircleProgressBar.scss';
</style>
