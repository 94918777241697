var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.verticalTabs == true
            ? _vm.productfilter == true
                ? 'filter_tabs'
                : 'vertical_tabs'
            : 'tabs'},[_c('div',{staticClass:"tab__header",class:{
            modular_header: _vm.modularDesign === true,
            carousel_header: _vm.carousel === true,
        }},[_c('div',{staticClass:"left-button-container"},[(_vm.carousel === true)?_c('div',{staticClass:"left",on:{"click":function($event){$event.stopPropagation();return _vm.Scroll('left')}}},[_c('CaretSVGVue',{attrs:{"direction":"left"}})],1):_vm._e()]),_c('ul',{staticClass:"tabs__header-list",class:{
                modular_header_list: _vm.modularDesign == true,
                centerHeader: _vm.centerHeader,
                carousel: _vm.carousel === true,
            }},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:((tab.title) + "_" + index),class:{
                    modular_div: _vm.modularDesign === true,
                    full_tab_selected:
                        _vm.fullWidth === true && index === _vm.selectedIndex,
                    fullWidth: _vm.fullWidth === true,
                    tab__selected:
                        _vm.fullWidth !== true && index === _vm.selectedIndex,
                    no_border: _vm.ehd === 'desktop',
                    no_border_mobile: _vm.ehd === 'mobile',
                },attrs:{"id":("" + index)},on:{"click":function($event){_vm.selectTab(index), _vm.selectedTabTitle(tab.title)}}},[(tab.img)?_c('img',{class:{
                        img__selected: index == _vm.selectedIndex,
                        blurTabs: tab.blur == true,
                    },attrs:{"src":tab.img,"alt":tab.title}}):_vm._e(),_c('li',[_vm._v(" "+_vm._s(tab.title)+" ")])])}),0),_c('div',{staticClass:"right-button-container"},[(_vm.carousel === true)?_c('div',{staticClass:"right",on:{"click":function($event){$event.stopPropagation();return _vm.Scroll('right')}}},[_c('CaretSVGVue',{attrs:{"direction":"right"}})],1):_vm._e()]),_vm._t("header")],2),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }