var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle-progressbar"},_vm._l((_vm.phases),function(phase,index){return _c('div',{key:index + '_' + phase.phase_name,staticClass:"progressbar"},[_c('span',{staticClass:"marker",class:{   
                tick: phase.completed == true,
                hollow: phase.phase_status == 'Processing'
            }}),_vm._l((phase.tasks),function(task,index){return _c('div',{key:index +'_'+ task},[_c('span',{staticClass:"line",class:{
                    active_line: task.status == 'Finished',
                },style:({ width: (100 / phase.tasks.length)*(index+1) + '%' })})])}),_c('span',{staticClass:"name",class:{
                active: phase.phase_status == 'Finished',
            }},[_vm._v(" "+_vm._s(phase.phase_name)+" ")]),_c('span',{staticClass:"tasks"},[_vm._v(" "+_vm._s(phase.tasks.length)+" Tasks ")])],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }