<template>
    <div
        :class="
            verticalTabs == true
                ? productfilter == true
                    ? 'filter_tabs'
                    : 'vertical_tabs'
                : 'tabs'
        "
    >
        <div
            class="tab__header"
            :class="{
                modular_header: modularDesign === true,
                carousel_header: carousel === true,
            }"
        >
            <div class="left-button-container">
                <div
                    v-if="carousel === true"
                    class="left"
                    @click.stop="Scroll('left')"
                >
                    <CaretSVGVue direction="left" />
                </div>
            </div>
            <ul
                class="tabs__header-list"
                :class="{
                    modular_header_list: modularDesign == true,
                    centerHeader: centerHeader,
                    carousel: carousel === true,
                }"
            >
                <div
                    v-for="(tab, index) in tabs"
                    :key="`${tab.title}_${index}`"
                    :id="`${index}`"
                    @click="selectTab(index), selectedTabTitle(tab.title)"
                    :class="{
                        modular_div: modularDesign === true,
                        full_tab_selected:
                            fullWidth === true && index === selectedIndex,
                        fullWidth: fullWidth === true,
                        tab__selected:
                            fullWidth !== true && index === selectedIndex,
                        no_border: ehd === 'desktop',
                        no_border_mobile: ehd === 'mobile',
                    }"
                >
                    <img
                        v-if="tab.img"
                        :src="tab.img"
                        :class="{
                            img__selected: index == selectedIndex,
                            blurTabs: tab.blur == true,
                        }"
                        :alt="tab.title"
                    />
                    <li>
                        {{ tab.title }}
                    </li>
                </div>
            </ul>
            <div class="right-button-container">
                <div
                    v-if="carousel === true"
                    class="right"
                    @click.stop="Scroll('right')"
                >
                    <CaretSVGVue direction="right" />
                </div>
            </div>
            <slot name="header"></slot>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import CaretSVGVue from '@/componentsV3/SVG/CaretSVG/CaretSVG.vue'

export default {
    props: [
        'isselected',
        'modularDesign',
        'fullWidth',
        'centerHeader',
        'ehd',
        'ehdHeader',
        'verticalTabs',
        'customClass',
        'carousel',
        'productfilter',
    ],
    components: {
        CaretSVGVue,
    },
    data() {
        return {
            selectedIndex: 0, // the index of the selected tab,
            tabs: [], // all of the tabs
            scrollPosition: 0,
        }
    },
    created() {
        this.tabs = this.$children
    },
    mounted() {
        this.selectTab(this.isselected)
        if (this.carousel) {
            this.tabs = this.tabs.filter(tab => {
                return String(tab.$vnode.componentOptions.tag) === 'Tab'
            })
        }
    },
    watch: {
        isselected(e) {
            this.selectTab(this.isselected)
        },
    },
    methods: {
        Scroll(direction) {
            const scrollBar = document.querySelector('.carousel')
            this.scrollPosition = scrollBar.scrollLeft

            switch (direction) {
                case 'left':
                    if (this.scrollPosition > 0) {
                        this.scrollPosition -= 120
                        this.scrollPosition > 0
                            ? scrollBar.scrollTo(this.scrollPosition, 0)
                            : (scrollBar.scrollTo(0, 0),
                              (this.scrollPosition = 0))
                    }
                    break
                case 'right':
                    if (
                        this.scrollPosition <
                            scrollBar.scrollWidth + scrollBar.scrollLeft &&
                        this.scrollPosition + 120 <
                            scrollBar.scrollWidth + scrollBar.scrollLeft
                    ) {
                        this.scrollPosition += 120
                        this.scrollPosition <
                        scrollBar.scrollWidth + scrollBar.scrollLeft
                            ? scrollBar.scrollTo(this.scrollPosition, 0)
                            : (scrollBar.scrollTo(
                                  scrollBar.scrollWidth + scrollBar.scrollLeft,
                                  0,
                              ),
                              (this.scrollPosition =
                                  scrollBar.scrollWidth + scrollBar.scrollLeft))
                    }

                    break
                default:
                    break
            }
        },
        selectTab(i, title) {
            this.selectedIndex = i
            this.$emit('selectedTab', i)
            // loop over all the tabs
            this.tabs
                .filter(tab => {
                    return tab.title !== undefined
                })
                .forEach((tab, index) => {
                    tab.isActive = index === i
                })
        },
        selectedTabTitle(title) {
            this.$emit('seletedTabTitle', title)
        },
    },
}
</script>
<style lang="scss">
@import './Tabs.scss';
</style>
